function ipDataCheck(data) {

    var ip_data = $(data).val();
    ip_data_length = ip_data.length;



    if (!(/^[0-9]+$/.test(ip_data))) {
        $(data).val('');
        $(data).addClass('invalid_ip_data');
        return false;
    }

     if (ip_data_length==3){

         $(data).next('.input-filed').focus();
     }

    if (ip_data_length > 3 || ip_data_length == 0) {
        $(data).val('');
        $(data).addClass('invalid_ip_data');
        return false;
    }

    $(data).removeClass('invalid_ip_data');

}

function sipConnect() {

    if ($('#static_route').is(':checked')) {
        $('#sip_connect_btn').show();
    } else {
        $('#sip_connect_btn').hide();
    }

}


function ipDataCheck1(data) {

    var ip_data = $(data).val();
    ip_data_length = ip_data.length;


    if (!(/(^[0-1]?[0-9]?[0-9]$)|(^[2][0-4][0-9]$)|(^25[0-5]$)/.test(ip_data))) {
        $(data).val('');
        $(data).addClass('invalid_ip_data');
        return false;
    }

     if (ip_data_length==3){

         $(data).next('.input-filed').focus();
     }

    if (ip_data_length > 3 || ip_data_length == 0) {
        $(data).val('');
        $(data).addClass('invalid_ip_data');
        return false;
    }

    $(data).removeClass('invalid_ip_data');

}