function t38_enabled_change() {
    var type = document.getElementById('UDPTL_enabled').value;
    if (type == 'yes') {
        set_visible('tr_errorcorrection', true);
        set_visible('tr_maxdatagram', true);
        set_visible('tr_faxdetect', true);
        set_visible('tr_faxactivity', true);
        set_visible('tr_faxtimeout', true);
    } else if (type == 'no') {
        set_visible('tr_errorcorrection', false);
        set_visible('tr_maxdatagram', false);
        set_visible('tr_faxdetect', false);
        set_visible('tr_faxactivity', false);
        set_visible('tr_faxtimeout', false);
    }
}

function anonymous_click(value) {
    if (value) {
        document.getElementById('sip_username').value = '';
        document.getElementById('sip_password').value = '';

        document.getElementById('sip_username').disabled = true;
        document.getElementById('sip_password').disabled = true;
        document.getElementById('sip_username').readOnly = true;
        document.getElementById('sip_password').readOnly = true;
    } else {
        document.getElementById('sip_username').disabled = false;
        document.getElementById('sip_password').disabled = false;
        document.getElementById('sip_username').readOnly = false;
        document.getElementById('sip_password').readOnly = false;
    }
}


var sip_ip_clear = false;

function registration_change(load) {
    value = document.getElementById('registration').value;

    if (value == 'client') {
        document.getElementById('authentication_user').disabled = false;
        document.getElementById("authentication_user").style.backgroundColor = "";
        document.getElementById('remote_secret').disabled = false;
        document.getElementById("remote_secret").style.backgroundColor = "";
        document.getElementById('port').disabled = false;
        document.getElementById("port").style.backgroundColor = "";
        document.getElementById('register_extension').disabled = false;
        document.getElementById("register_extension").style.backgroundColor = "";
        document.getElementById('register_user').disabled = false;
        document.getElementById('register_user').style.backgroundColor = "";

        document.getElementById('sip_ip').readOnly = false;


        if (sip_ip_clear)
            document.getElementById('sip_ip').value = '';

        document.getElementById('anonymous').checked = false;
        document.getElementById('anonymous').disabled = true;
        anonymous_click(false);
        if (load == 0)
            document.getElementById('qualify').value = 'yes';
    } else {
        document.getElementById('authentication_user').disabled = true;
        document.getElementById("authentication_user").style.backgroundColor = "#E0E0E0";
        document.getElementById('remote_secret').disabled = true;
        document.getElementById("remote_secret").style.backgroundColor = "#E0E0E0";
        document.getElementById('register_extension').disabled = true;
        document.getElementById("register_extension").style.backgroundColor = "#E0E0E0";
        document.getElementById('register_user').disabled = true;
        document.getElementById("register_user").style.backgroundColor = "#E0E0E0";

        if (value == 'none') {
            document.getElementById('port').disabled = false;
            document.getElementById("port").style.backgroundColor = "";

            document.getElementById('sip_ip').readOnly = false;
            if (sip_ip_clear)
                document.getElementById('sip_ip').value = '';

            document.getElementById('anonymous').disabled = false;
            document.getElementById('anonymous').checked = true;
            if (load == 0)
                document.getElementById('qualify').value = 'yes';
        } else if (value == 'server') {
            document.getElementById('port').disabled = true;
            document.getElementById("port").style.backgroundColor = "#E0E0E0";
            document.getElementById('sip_ip').value = 'dynamic';
            document.getElementById('sip_ip').readOnly = true;

            sip_ip_clear = true;

            document.getElementById('anonymous').checked = false;
            document.getElementById('anonymous').disabled = true;
            if (load == 0)
                document.getElementById('qualify').value = 'yes';
            anonymous_click(false);
        }
    }
}
